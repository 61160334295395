.page-container {
    height: 88vh;
    display: flex;
    flex-direction: column;
  }
  
  .page-navbar {
    background-color: #0d2436;
    font-family: "Roboto";
    font-size: 20px;
    color: white;
    padding: 1.5vw;
  }
  
  .page-body {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
  }
  
  .admin-sidebar {
    width: 16vw;
    padding: 1vw;
    background-image: radial-gradient(rgb(103, 130, 250) 10%,  #140cad);
    font-family: "Roboto";
    font-size: 1.6vw;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .content {
    background-color: #f6f8fa;
    font-family: "Roboto";
    font-size: 1.2vw;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .page-footer {
    background-color: #0782e0;
    font-family: "Roboto";
    font-size: 1.6vw;
    color: white;
    padding: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3vh;
    border-radius: 20px;
    margin: 10px;
    position: fixed;
    width: 95vw;
    bottom: 0;
  }

  .page-item-footer{
    background-image: radial-gradient(rgb(103, 130, 250) 10%,  #352bf0);
    font-family: "Roboto";
    color: white;
    padding: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .admin-sidebar-button{
    background-color: transparent;
    border: none;
    font-size: 1.5vw;
    border-radius: 10px;
    padding: 1vw;
    margin-bottom: 1vh;
    color: white;
    width: 16vw;
    text-align: left;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added smooth transition */
  }

  .admin-sidebar-button:hover{
    background-color: #3a31eb;
    transform: scale(1.05); /* Expands the row slightly */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
  }
  .sidebar-icon{
    margin-right: 10px;
  }
  
  .admin-footer-button{
    background-color: transparent;
    border: none;
    font-size: 30px;
    color: white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    padding-top: 7px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .admin-footer-button:hover{
    transform: scale(1.5);
  }
  .social-icon{
    margin-right: 0.5vw;
   
  }
  .content-us{
    margin: 0px;
    padding: 0px;
  }
  .admin{
    font-size: 5vw;
    text-align: center;
    font-family: roboto;
  }
  .admin-image{
    height: 15vh;
    align-self: center;
    margin-bottom: 5vh;
  }
  .admin-logout-button{
    background-color: #8c0404;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5vw;
    width: 8vw;
    height: 5vh;
    align-self: flex-end;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added smooth transition */
  }
  .admin-logout-button:hover{
    background-color: #990e0e;
    transform: scale(1.05); /* Expands the row slightly */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
  }
  .admin-cards-container{
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .admin-buttons-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .Admin-card1{
    box-shadow: 0 2px 8px 0 rgba(121, 117, 117, 0.678), 0 4px 20px 0 rgba(105, 102, 102, 0.603);
    width: 20vw;
    text-align: center;
    margin: 20px;
    padding: 10px;
    border-radius: 1vw;
    font-family: roboto;
}
.admin-full-container{
    display: flex;
    flex-direction: row;
}
.admin-button-select{
    background-color: rgb(103, 71, 255);
    border: none;
    padding: 7px;
    font-weight: bold;
    border-radius: 4px;
    color: white;
}
.admin-image-botton{
  background-color: transparent;
  border: none;
  align-self: center;
}
@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {
    
}

@media only screen and (max-width: 480px) {
    
}