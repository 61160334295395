.wallet-topbar-container{
    background-color: #2e3a4a;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}
.wallet-icon{
    color: rgb(63, 156, 39);
    font-size: 50px;
    margin-right: 10px;
}
.total-balance{
    margin: 0px;
    font-size: 10px;
    font-family: "Roboto";
    color: white;
}
.total-balance-rupee{
    margin: 0px;
    font-size: 30px;
    font-family: "Roboto";
    color: rgb(77, 170, 34);
}
.wallet-cards-container{
    display: flex;
    flex-direction: row;
    padding: 15px;
}
.wallet-card-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-right: 20px;
}
.add-money{
    height: 30px;
    width: 100px;
    border-radius: 5px;
    border: none;
    background-color: rgb(6, 6, 204);
    color: white;
    cursor: pointer;
    align-self: flex-end;
}
.wallet-card{
    border-radius: 10px;
    height: 250px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 10px;
    padding: 10px;
    background-color: white;
    display: flex;
}
.payment-card{
    background-color: #171A3D;
    flex-grow: 1;
    border-radius: 10px;
    padding: 10px;
}
.payment-type-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgb(111, 128, 160);
}
.payment-radio-button{
    margin-right: 5px;
}
.payment-type-title{
    font-size: 20px;
    color: white;
    color: rgb(228, 142, 30);
    margin-bottom: 10px;
    font-weight: 600;
}
p{
    margin: 0px;
}
.payment-type-name{
    color: white;
    cursor: pointer;
}
.payment-card-image{
    height: 30px;
    width: 50px;
}
.wallet-transaction-card{
    border-radius: 10px;
    flex-grow: 1;
    height: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-right: 10px;
    padding: 10px;
}
.transaction-cards{
    height: 350px;
    overflow-y: auto;
}
/* 16 */
.transaction-cards::-webkit-scrollbar-thumb {
    background: rgb(1, 18, 77);
    border-radius: 10px;
}

/* 16 */
.transaction-cards::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* 16 */
.transaction-cards::-webkit-scrollbar {
    width: 3px;
}


.transaction-title{
    margin: 0px;
    font-family: "Roboto";
    font-size: 20px;
    color: rgb(24, 2, 100);
    margin-bottom: 15px;
}
.transaction-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    padding: 10px;
    background-color: #eaeaec;
    margin-bottom: 5px;
    margin-right: 5px;
    background-image: radial-gradient(rgb(178, 189, 240) 10%, #d0cef5);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added smooth transition */
}
.transaction-card:hover{
    transform: scale(1.02); /* Expands the row slightly */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
}
.transaction-type{
    margin: 0px;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 600;
    color: #020e52;
}
.transaction-id{
    margin: 0px;
    font-size: 10px;
    font-family: "Roboto";
    color: #4d4e50;
}
.ifen{
    margin: 0px;
}
@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 480px) {
    .wallet-card{
        flex-direction: column;
        height: 100%;
    }
}

