.selected-button-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 10px;
}
.selected-button{
    flex-grow: 1;
    font-size: 16px;
    padding: 10px;
    font-weight: bold;
    font-family: "Roboto";
    border: 1px solid rgb(88, 88, 252);
    background-color: white;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added smooth transition */
}
.selected-button:hover{
    transform: scale(1.02); /* Expands the row slightly */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
    border: none;
}
.selected-button-active{
    flex-grow: 1;
    font-size: 16px;
    padding: 5px;
    background-color: rgb(74, 7, 231);
    color: white;
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added smooth transition */
}
.selected-button-active:hover{
    transform: scale(1.05); /* Expands the row slightly */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
    border: none;
}
.available{
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.accepted{
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}