.table-row {
    border-bottom: 5px solid white;
    background-image: radial-gradient(rgb(178, 189, 240) 10%, #d0cef5);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added smooth transition */
}

.table-row:hover {
    background-image: radial-gradient(rgb(197, 206, 248) 10%, #a39ff5);
    transform: scale(1.05); /* Expands the row slightly */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
}

.action, .action-mobile {
    transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for buttons */
}

.action:hover, .action-mobile:hover {
    transform: scale(1.2); /* Slightly enlarges buttons when hovered */
}

.table-data{
    text-align: center;
    padding: 10px;
}
.start-data{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.end-data{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    padding-top: 15px;
}
.car-image{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.action{
    width: 80px;
    height: 30px;
    border: none;
    margin-right: 8px;
    border-radius: 15px;
}
.action-mobile{
    width: 60px;
    height: 25px;
    border: none;
    margin-right: 5px;
    border-radius: 10px;
    font-size: 10px;
}
.accept{
    background-color: green;
    color: white;
}
.accept:hover{
    background-color: rgb(29, 179, 29);
}
.ignore{
    background-color: grey;
    color: white;
}
.ignore:hover{
    background-color: rgb(54, 53, 53);
}