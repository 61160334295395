.maps-main-container{
    height: 75vh;
}

.maps-button-container{
    display: flex;
    padding: 20px;
    background-color: #32545B;
    justify-content: flex-end;
}
.maps-button-container-1{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #32545B;
    width: 98%;
    padding-left: 20px;
    padding-right: 20px;

}
.maps-buttons{
    display: flex;
}
.maps-button{
    border: none;
    font-size: 16px;
    margin-right: 10px;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(23, 96, 233);
    color: white;
    font-family: serif;
    font-weight: 700;
}

.maps-search{
    width: 300px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.maps-search:focus{
    border: none;
}