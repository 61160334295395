.driver-homepage {
    text-align: center;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .announcement {
    color: #fff;
    background: #007bff;
    padding: 10px;
  }
  
  .feature-cards {
    margin-top: 30px;
  }
  
  .card-group {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .card {
    border: 1px solid #007bff;
    border-radius: 5px;
    padding: 15px;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .card h3 {
    margin: 0 0 10px;
  }
  
  .card-button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .card-button:hover {
    background: #0056b3;
  }
  