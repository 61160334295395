

.signin-main-container{
    background-color: #c9d6ff;
    background: linear-gradient(to right, #e2e2e2, #c9d6ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
}

.signup-heading{
    margin: 0px;
}

.signin-container{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.signin-container p{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.signin-container span{
    font-size: 12px;
}

.signin-container a{
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}


.signin-container .signin-button, .next-button, .previous-button{
    background-color: #382ef8;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}
.signin-button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.previous-button{
    margin-right: 10px;
}
.next-button{
    align-self: flex-end;
}
.keep-signup-button{
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 8px 0 10px;
    background-color: transparent;
    border: none;
    align-self: flex-end;
}

.signin-container button.hidden{
    background-color: transparent;
    border-color: #fff;
}

.signin-container form{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.signin-container input, textarea, select{
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}
select{
    width: 110%;
}

.form-container{
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in{
    left: 0;
    width: 50%;
    z-index: 2;
}

.signin-container.active .sign-in{
    transform: translateX(100%);
}

.sign-up{
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.signin-container.active .sign-up{
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move{
    0%, 49.99%{
        opacity: 0;
        z-index: 1;
    }
    50%, 100%{
        opacity: 1;
        z-index: 5;
    }
}

.social-icons{
    margin: 20px 0;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.social-icons a{
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}

.toggle-container{
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 1000;
}

.signin-container.active .toggle-container{
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.toggle{
    background-color: #382ef8;
    height: 100%;
    background: linear-gradient(to right, rgb(42, 77, 233), #382ef8);
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.signin-container.active .toggle{
    transform: translateX(50%);
}

.toggle-panel{
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.brands{
    font-size: 28px;
    border-radius: 12px;
}

.security-image{
    height: 200px;
}

.toggle-left{
    transform: translateX(-200%);
}

.signin-container.active .toggle-left{
    transform: translateX(0);
}

.toggle-right{
    right: 0;
    transform: translateX(0);
}

.signin-container.active .toggle-right{
    transform: translateX(200%);
}

/* Existing CSS styles */

@media only screen and (max-width: 768px) {
    .signin-container {
        width: 90%;
    }

    .form-container {
        padding: 0 10px;
    }
    
    .social-icons {
        width: 100%;
        justify-content: space-around;
    }

    .signin-container input {
        font-size: 12px;
        padding: 8px 10px;
    }

    .signin-button {
        padding: 6px 30px;
        font-size: 10px;
    }

    .keep-signup-button {
        padding: 5px;
        font-size: 10px;
    }

    .toggle-container {
        display: block;
        width: 100%;
        height: 50px;
        text-align: center;
    }

    .toggle-container .toggle-left,
    .toggle-container .toggle-right {
        display: none;
    }
    .sign-up {
        transform: translateX(0) !important;
      }
      .signin-container.active .sign-up {
        transform: translateX(0) !important;
      }
}


