@keyframes topInBottom {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.nav{
  background-image: radial-gradient(rgb(42, 77, 233),  #382ef8);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    animation: topInBottom 1s ease-in-out;
  } 

  /* Switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-right: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .nav-items-container{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;
    width: 70vw;
    position: relative;
  }

  .nav-cont-2{
    background-color: orangered;
    height: 40px;
    width: 40%;
    align-self: flex-end;
    border-top-left-radius: 100px; /* Adjusted for a large quarter-circle effect */
    border-bottom-right-radius: 50px; /* Added for a curved bottom-right corner */
    transition: all 0.3s ease; /* Adds smoothness to any future interaction */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-right: 5px;
  }
  .nav-container-1{
    display: flex;
    flex-direction: column;
  }

  .social-nav-icon{
    color: white;
    font-size: 20px;
  }

  .nav-row-item{
    font-size: 24px;
  }
  .nav-signup-button{
    background-color: white;
    width: 100px;
    height: 30px;
    color: black;
    border: none;
    border-radius: 2px;
    font-size: 12px;
    margin-top: 5px;
    font-weight: bold;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider-check:checked + .slider {
    background-color: #2196F3;
  }
  
  .slider-check:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  .slider-check:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  /* Switch */

  .slider-notification-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .nav-Home{
    color: white;
    text-decoration: none;
    font-size: 1.6vw;
    font-family: roboto;
  }
  .nav-Home-logo{
    font-weight: 600;
    width: 5vw;
    text-align: center;
    text-decoration: none;
    font-family: "cursive";
    cursor: pointer;
  }
  .app-logo{
    height: 80px;
    width: 300px;
    border-right: 20px solid white;
  }
  .home-notification-button{
    color: white; 
    text-decoration: none;
    font-size: 34px;
    font-family: roboto;
    font-weight: bold;
    background-color: transparent;
    border : none;
    cursor: pointer;
    margin: 0px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added smooth transition */
  }
  .home-notification-button:hover{
    transform: scale(1.2); /* Expands the row slightly */
  }
  
  
  .sidebar {
    width: 30vw;
    height: 50%;
    background-color: #ebeaea;
    position: fixed;
    top: 10;
    right: -30vw; /* Initially hide the sidebar on the right side */
    transition: right 0.5s ease;  
    border-radius: 10px; /* Apply transition effect to right property */
  }
  
  /* .sidebar.open {
    right: 0; 
  } */
  
  .navButton {
    position: absolute;
    top: 10px;
    left: 10px; /* Adjust button position */
  }
  .home-sidebar-container{
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    border-radius: 10px;
    height: 300px;
    overflow-y: auto;
    list-style-type: none;
    margin-left: 0px;
  }
  .home-sidebar-container::-webkit-scrollbar-thumb {
    background: rgb(1, 18, 77);
    border-radius: 10px;
}

/* 16 */
.home-sidebar-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* 16 */
.home-sidebar-container::-webkit-scrollbar {
    width: 3px;
}
  .home-sidebar-top-container{
    align-self: flex-end;
  }
  .note-title{
    text-align: center;
    font-family: "Roboto";
    margin: 0px;
  }
  .home-sidebar-bottom-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 30vh;
  }
  .home-sidebar-descreption{
    text-align: center;
    font-size: 1.5vw;
    color: white;
  }
  .home-sidebar-signup-button{
    border: none;
    height: 5vh;
    border-radius: 5px;
    width: 10vw;
    color: white;
    background-color: #9B5706;
  }
  .home-sidebar-span{
    color: white;
  }
  .home-sidebar-login-button{
    background-color: transparent;
    padding: 1px;
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    margin-left: 0.8vw;
  }
  button{
    cursor: pointer;
  }
  .note-message{
    font-size: 12px;
    background-color: white;
    margin-bottom: 3px;
    padding: 5px;
    border-radius: 10px;
    list-style-type: none;
    margin-right: 10px;
  }
  .feature-nav-icon{
    display: none;
  }
  .customer-signin-container{
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  @media only screen and (max-width: 1024px) {

  }
  
  @media only screen and (max-width: 768px) {
    .sidebar {
      width: 80vw;
      height: 57%;
      background-color: #ebeaea;
      position: fixed;
      top: 10;
      right: -80vw; /* Initially hide the sidebar on the right side */
      transition: right 0.5s ease;
      border-radius: 10px; /* Apply transition effect to right property */
    }
    .app-logo{
      height: 70px;
      width: 40vw;
      border-right: 10px solid white;
    }
    .nav-cont-2{
      height: 35px;
    }
    .social-nav-icon{
      font-size: 16px;
    }
    .nav-items-container{
      display: none;
    }
    .nav-cont-2{
      width: 50vw;
    }
    .feature-nav-icon{
      display: block;
      font-size: 34px;
      font-weight: bold;
      color: white;
      align-self: flex-end;
      margin-right: 20px;
    }
    .nav-items-container-mbl {
      display: block;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: fixed;
      height: 300px;
      width: 200px;
      background-color: #2196F3;
      top: 80px;
      left: -250px; /* Hide off the left initially */
      transition: left 0.5s ease; /* Smooth transition for sliding effect */
      padding-left: 10px;
      z-index: 1;
    }
    
    /* When open, make it slide in */
    .nav-items-container-mbl.open {
      left: 0; /* Bring it into view */
    }
  }

  