*{
    margin: 0px;
}
.feedback-main-container{
    display: flex;
    flex-direction: row;
    height: 90%;
    padding: 20px 20px;
}
.feedback-card, .feedback-card-1{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 50%;
    margin-bottom: 20px;
}
.feedback-card-1{
    flex-direction: row;
    align-items: center;
}
.feedback-cards{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    border-radius: 10px;
}
.feedbacks-card{
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.feedbacks-card::-webkit-scrollbar-thumb {
    background: rgb(1, 18, 77);
    border-radius: 10px;
}

/* 16 */
.feedbacks-card::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* 16 */
.feedbacks-card::-webkit-scrollbar {
    width: 3px;
}
.feedback-profile-container{
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}
.feedbacks-container{
    border-bottom: 1px solid rgb(131, 129, 129);
    padding: 10px;
}
.filled-star{
    color: rgb(255, 187, 0);
}
.empty-star{
    color: rgb(120, 125, 129);
}
.feedback-profile{
    height: 50px;
    width: 50px;
    border-radius: 80%;
    margin-right: 10px;
}
.feedback-username{
    font-weight: bold;
    font-size: 16px;
}
.feedback-review{
    font-size: 14px;
    font-family: serif;
    width: 600px;
    color: rgb(73, 71, 71);
}
