.settings-container{
    padding: 20px;
}
.settings-title{
    font-family: monospace;
    color: rgb(25, 25, 177);
    font-size: 34px;
}

.settings-features-cards{
    display: grid;
  grid-template-columns: repeat(4, 1fr); /* Display 3 cards per row */
  gap: 30px; /* Space between the cards */
  padding: 30px;
  
}
.settings-feature-card{
    width: auto;
    height: auto;
    box-shadow: 0px 4px 15px rgba(3, 16, 187, 0.2);
    border-radius: 10px;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 2px solid rgba(14, 29, 236, 0.2);
}
.settings-feature-card:hover{
    transform: scale(1.1); /* Expands the row slightly */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
}
.settings-feature-icon{
    font-size: 80px;
    color: rgb(64, 64, 241);
}
.settings-feature-name{
    color: rgb(3, 3, 75);
    font-size: 20px;
    font-weight: bold;
    font-family: "Roboto";
    margin: 0px;
    text-align: center;
}
@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {
    .settings-features-cards{
      grid-template-columns: repeat(3, 1fr); 
    }
}

@media only screen and (max-width: 480px) {
    .settings-features-cards{
        grid-template-columns: repeat(1, 1fr); 
      }
}