
  
  .location-details-heading {
    font-size: 35px;
    font-family: roboto;
    text-align: center;
  }
  .location-details-form-full-container {
    box-shadow: 0 2px 8px 0 rgba(14, 13, 13, 0.678), 0 4px 20px 0 rgba(0, 0, 0, 0.603);
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    width: 320px;
    position: fixed;
    top: 85px;
    right: -380px; /* Hide off the left initially */
    transition: right 0.5s ease;
  }

  .location-details-form-full-container.open-details{
    right: 0;
  }
  
  .input-full-container {
    display: flex;
    flex-direction: column;
  }
  
  .location-details-input-tag {
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
    height: 24px;
    width: 300px;
    background-color: white;
    border: 1px solid rgb(131, 128, 128);
  }
  
  .location-details-submit-button{
    padding:8px 15px 8px 15px;
    font-size: 14px;
    align-self: flex-end;
    background-color: orangered;
    border-radius: 5px;
    border: none;
    color: white

  }
  
  .location-details-paragraph {
    font-size: 18px;
    margin-top: 5px;
    margin-top: 10px;
  }
  
 
 
  @media (max-width: 1024px) {
    
  }
  
  @media (max-width: 768px) {
    
  }
  
  @media (max-width: 480px) {
    
  }
  