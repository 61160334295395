.driver-profile-container{
    display: flex;
    flex-direction: row;
    background-color: #C1C9CD;
    font-family: Monospace;
    padding: 10px;
    height: 80vh;
}

.driver-profile-section{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}   

.driver-profile-image{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    align-self: center;
    border: 3px solid #5F6E74;
}

.driver-profile-name{
    font-size: 36px;
    font-family: Monospace;
    margin-bottom: 10px;
    color: #1C3D4A;
    font-weight: bold;
    text-decoration: underline;
}

.driver-profile-id-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    margin-bottom: 10px;
    background-color: #D9DDDE;
    font-family: Monospace;
}

.id-value{
    font-size: 12px;
    color: #1C3D4A;
    font-weight: bold;
}

.driver-profile-icons-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.driver-profile-icon-button{
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    flex-basis: 20%;
    margin-bottom: 10px;
}

.driver-profile-icon{
    font-size: 20px;
    background-color: #5F6E74;
    color: white;
    padding: 10px;
    border-radius: 50%;
}

.driver-profile-side-name{
    font-size: 24px;
    font-family: Monospace;
    color: #1C3D4A;
    font-family: Monospace;
}

.driver-profile-lisence-details-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Monospace;
}

.driver-profile-car-image{
    height: 140px;
}

.driver-profile-section-2{
    display: flex;
    font-family: Monospace;
}

.driver-profile-label{
    font-size: 18px;
    font-family: Roboto;
    font-weight: bold;
    color: #1C3D4A;
    font-family: Monospace;
}

.driver-profile-section-1{
    width: 25vw;
    margin-right: 10px;
}

.driver-profile-name-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 16px;
    padding: 5px;
    background-color: #D9DDDE;
    margin-top: 5px;
    margin-bottom: 15px;
}   

.driver-profile-document-container{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding: 10px;
}

.driver-profile-edit-icon{
    font-size: 16px;
    background-color: #5F6E74;
    color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.driver-profile-documents-name{
    background-color: #1C3D4A;
    color: white;
    padding: 10px;
    font-size: 24px;
    font-family: Monospace;
    border-radius: 5px;
}

.driver-profile-buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
}

.driver-profile-details-button-1, .driver-profile-details-button-2, .driver-profile-details-button-3{
    padding: 10px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 5px;
    border: none;
    background-color: red;
    color: white;
}

.driver-profile-details-button-2{
    background-color: green;
}

.driver-profile-details-button-3{
    background-color: #49585e;
}

.driver-profile-label-input{
    background-color: transparent;
    border: none;
    padding: 5px;
    width: 80%;
}

.driver-profile-label-input:focus{
    outline: none;
    border: none;
}
.driver-profile-docs-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}
.driver-profile-doc-container{
    width: 40%;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-right: 5px;
    margin-bottom: 5px;
    align-items: center;
}
.driver-profile-doc-nav-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    background-color: #485c64;
    color: white;
    margin-bottom: 10px;
}
.driver-profile-doc-image{
    width: 100%; 
    height: 60px;
}
.driver-profile-doc-nav-descreption{
    font-size: 10px;
    font-weight: bold;
    font-family: Monospace;
}
.driver-profile-name-status-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.driver-pending-status{
    color: rgb(218, 156, 41);
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.pending-dot-icon{
    font-size: 24px;
}
.verified-doc{
    color: rgb(55, 223, 55);
}
.profile-dropdown{
    width: 200px;
    position: fixed;
    border-radius: 10px;
    background-color: white;
    right: 15px;
    padding: 16px;
    border: 1px solid red;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.profile-dropdown-content{
    font-size: 14px;
    font-family: Georgia, serif;
    color: rgb(146, 1, 1);
    text-align: center;
}
.profile-dropdown-span{
    font-weight: bold;
}
@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {
    .driver-profile-container{
        flex-direction: column;
        background-size: cover;
        height: auto;
    }
    .driver-profile-section-2{
        flex-direction: column;
        width: 100%;
    }
    .driver-profile-section-1{
        width: 100%;
    }
    .driver-profile-label-name{
        font-size: 16px;
    }
    .driver-profile-document-container{
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 480px) {
    
}